<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    append-to-body
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>统计进度</span>
    </div>

    <el-row>
      <el-steps :active="active" finish-status="success">
        <el-step title="更新排课数据" :status="firstStatus"></el-step>
        <el-step title="更新排课数据快照" :status="secondStatus"></el-step>
        <el-step title="检测数据完整性" :status="thirdlyStatus"></el-step>
        <el-step title="统计课时" :status="fourthlyStatus"></el-step>
      </el-steps>
    </el-row>

    <el-row class="verify">
      <el-col :span="8" class="verify-item">
        <div v-show="fistVerify">
          <p>是否更新数据？</p>
          <el-row class="mt-10">
            <el-button type="primary" size="mini" @click="firstConfirm">是</el-button>
            <el-button type="primary" size="mini" @click="firstDeny">否</el-button>
          </el-row>
        </div>
      </el-col>
      <el-col :span="8" class="verify-item">
        <div v-show="secondVerify">
          <p>是否更新数据？</p>
          <el-row class="mt-10">
            <el-button type="primary" size="mini" @click="secondConfirm">是</el-button>
            <el-button type="primary" size="mini" @click="secondDeny">否</el-button>
          </el-row>
        </div>
      </el-col>
      <el-col :span="8" class="verify-item">
        <div>
          <el-button type="primary" size="mini" @click="checkData" v-if="checkDataStatus">查看不完整数据</el-button>
          <el-button type="primary" size="mini" @click="checkDetection" v-if="checkDataStatus">重新检测</el-button>
        </div>
      </el-col>
    </el-row>

    <!--  -->
    <check-arr :show="checkDialog" @close="checkDialog=false" :checkArr="checkArr"></check-arr>
  </el-dialog>
</template>

<script>
import { checkStat, replaceSnapshot, replaceLessonInfo, statTeacherLessonDuration } from '@/api/statistics'
import CheckArr from '../check-arr/index'

export default {
  components: { CheckArr },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    periodId: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      active: 0,
      fistVerify: true,
      secondVerify: false,
      firstStatus: null,
      secondStatus: null,
      thirdlyStatus: null,
      fourthlyStatus: null,
      checkDataStatus: false,
      checkDialog: false,
      checkArr: []
    }
  },
  methods: {
    openDialog () {
    },

    close () {
      this.$emit('close')
    },

    firstConfirm () {
      replaceLessonInfo({
        periodId: this.periodId
      }).then(res => {
        if (res.state === 'success') {
          this.active = 1
          this.fistVerify = false
          this.secondVerify = true
          // this.checkStat()
        } else {
          this.firstStatus = 'error'
        }
      })
    },

    firstDeny () {
      this.active = 1
      this.fistVerify = false
      this.firstStatus = 'error'
      this.secondVerify = true
      // this.checkStat()
    },

    secondConfirm () {
      this.active = 2
      replaceSnapshot({
        periodId: this.periodId
      }).then(res => {
        if (res.state === 'success') {
          // this.statTeacherLessonDuration()
          this.secondVerify = false
          this.checkStat()
        } else {
          this.secondStatus = 'error'
        }
      })
    },

    secondDeny () {
      this.active = 2
      this.secondStatus = 'error'
      this.secondVerify = false
      // this.statTeacherLessonDuration()
      this.checkStat()
    },

    // 校验数据完整性
    checkStat () {
      checkStat({
        periodId: this.periodId
      }).then(res => {
        if (res.state === 'success') {
          if (res.body.length === 0) {
            this.active = 3
            this.statTeacherLessonDuration()
          } else {
            this.active = 3
            this.thirdlyStatus = 'error'
            this.checkDataStatus = true
            this.checkArr = res.body
          }
        } else {
          this.active = 3
          this.thirdlyStatus = 'error'
        }
      })
    },

    // 统计课时
    statTeacherLessonDuration () {
      statTeacherLessonDuration({
        periodId: this.periodId
      }).then(res => {
        if (res.state === 'success') {
          this.active = 4
          this.$alert('', '生成统计成功', {
            confirmButtonText: '确定',
            callback: action => {
              this.close()
            }
          })
        } else {
          this.active = 4
          this.fourthlyStatus = 'error'
        }
      })
    },

    // 查看不完整数据
    checkData () {
      this.checkDialog = true
    },

    checkDetection () {
      this.checkStat()
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.active = 0
        this.fistVerify = true
        this.secondVerify = false
        this.firstStatus = null
        this.secondStatus = null
        this.thirdlyStatus = null
        this.fourthlyStatus = null
        this.checkDataStatus = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.verify {
  width: calc(100% - 64px);
  margin-top: 10px;
}
.verify-item {
  height: 80px;
}
</style>
