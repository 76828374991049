<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="statisticsDialogTitle"></span>
    </div>
    <!-- formData.date: {{formData.date}} -->
    <!-- statisticsData：{{statisticsData}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="150px">
      <el-form-item label="选择统计日期：" prop="date">
        <el-date-picker
          v-model="formData.date"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="起始上课时间"
          end-placeholder="截止上课时间"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @blur="getClassAll();forceUpdate()"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="选择校区：" prop="campusIds">
        <el-select v-model="formData.campusIds" multiple filterable placeholder="请选择" @change="getClassAll();forceUpdate()">
          <el-option
            v-for="item in campusIdsList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="过滤班级：" prop="classIdsExclude" v-if="statisticsData !== null">
        <el-select v-model="formData.classIdsExclude" multiple filterable placeholder="请选择">
          <el-option
            v-for="item in classIdsExcludeList"
            :key="item.class_id"
            :label="item.name"
            :value="item.class_id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="saveStatistics">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>

    <!-- 进度条 -->
    <statistics-progress :show="progressDialog" @close="progressDialog=false" :periodId="periodId"></statistics-progress>
  </el-dialog>
</template>

<script>
import { setPeriod } from '@/api/statistics'
import { getCampusAll } from '@/api/senate/campus'
import { getClassAll } from '@/api/senate/class'
import statisticsProgress from '@/components/statistics/statistics-progress'

export default {
  components: {
    statisticsProgress
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    statisticsData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      statisticsDialogTitle: '',
      campusIdsList: [],
      classIdsExcludeList: [],
      // 日期选择器配置
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formData: {
        campusIds: [],
        classIdsExclude: []
      },
      rules: {
        date: [
          { required: true, message: '请选择统计日期', trigger: 'blur' }
        ],
        campusIds: [
          { required: true, message: '请选择校区', trigger: 'blur' }
        ]
      },
      progressDialog: false,
      periodId: ''
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        campusIds: [],
        classIdsExclude: []
      }
      if (this.statisticsData !== null) {
        this.statisticsDialogTitle = '修改统计课时'
        this.formData.campusIds = JSON.parse(this.statisticsData.campusIds)
        this.formData.classIdsExclude = JSON.parse(this.statisticsData.classIdsExclude)
        this.formData.date = [this.statisticsData.startTime.substring(0, 10), this.statisticsData.endTime.substring(0, 10)]
      } else {
        this.statisticsDialogTitle = '新增课时统计'
      }
      this.getCampusAll()
      this.getClassAll()
    },

    async getClassAll () {
      const res = await getClassAll({
        pageNum: 1,
        pageSize: 1000,
        campusIdSet: this.formData.campusIds,
        minLessonTime: this.formData.date[0],
        maxLessonTime: this.formData.date[1],
        needCount: true
      })
      this.classIdsExcludeList = res.body.list
    },

    forceUpdate () {
      this.$forceUpdate()
    },

    saveStatistics () {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          if (this.statisticsData !== null) {
            const res = await setPeriod({
              id: this.statisticsData.id,
              startTime: this.formData.date[0] + ' 00:00:00',
              endTime: this.formData.date[1] + ' 23:59:59',
              classIdsExclude: JSON.stringify(this.formData.classIdsExclude),
              campusIds: JSON.stringify(this.formData.campusIds)
            })
            this.periodId = res.body
            this.close()
            this.$emit('updateView')
            this.progressDialog = true
          } else {
            const res = await setPeriod({
              startTime: this.formData.date[0] + ' 00:00:00',
              endTime: this.formData.date[1] + ' 23:59:59',
              classIdsExclude: JSON.stringify(this.formData.classIdsExclude),
              campusIds: JSON.stringify(this.formData.campusIds)
            })
            this.periodId = res.body
            this.close()
            this.$emit('updateView')
            this.progressDialog = true
          }
        }
      })
    },

    async getCampusAll () {
      const res = await getCampusAll()
      this.campusIdsList = res.body
    },

    close () {
      this.$emit('close')
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
