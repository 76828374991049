<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
    width="50%"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>不完整数据</span>
    </div>

    <el-table
      :data="checkArr"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="类型" align="center" prop="reason" width="200"></el-table-column>
      <el-table-column label="详情" align="center" prop="reasonMsg"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    checkArr: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data () {
    return {
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
