var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("课时统计记录")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.addPeriod()
                },
              },
            },
            [_vm._v("新增课时统计")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "统计周期", align: "center", prop: "contest_id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.startTime.substring(0, 10))),
                    ]),
                    _vm._v(" ~ "),
                    _c("span", [
                      _vm._v(_vm._s(scope.row.endTime.substring(0, 10))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "统计校区", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.campusNames))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "过滤班级", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.classNamesExclude))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "250", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.againInfo(scope.row)
                          },
                        },
                      },
                      [_vm._v("重新统计")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.updateInfo(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改并统计")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-statistics", {
        attrs: {
          show: _vm.statisticsDialog,
          statisticsData: _vm.statisticsData,
        },
        on: {
          close: function ($event) {
            _vm.statisticsDialog = false
            _vm.statisticsData = null
          },
          updateView: _vm.getListPeriod,
        },
      }),
      _c("statistics-progress", {
        attrs: { show: _vm.progressDialog, periodId: _vm.periodId },
        on: {
          close: function ($event) {
            _vm.progressDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }