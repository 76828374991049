<template>
  <el-row class="table">
    <span class="title">课时统计记录</span>
    <el-row class="row-bg">
      <el-button type="info" size="medium" @click="addPeriod()" class="tb-button">新增课时统计</el-button>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="统计周期" align="center" prop="contest_id">
        <template slot-scope="scope">
          <span>{{scope.row.startTime.substring(0, 10)}}</span> ~
          <span>{{scope.row.endTime.substring(0, 10)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="统计校区" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.campusNames}}</span>
        </template>
      </el-table-column>
      <el-table-column label="过滤班级" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.classNamesExclude}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="againInfo(scope.row)">重新统计</el-button>
          <el-button type="text" @click="updateInfo(scope.row)">修改并统计</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- 添加统计周期 -->
    <add-statistics
      :show="statisticsDialog"
      @close="statisticsDialog=false;
      statisticsData=null"
      :statisticsData="statisticsData"
      @updateView="getListPeriod"
    ></add-statistics>

    <!-- 统计步骤 -->
    <statistics-progress :show="progressDialog" @close="progressDialog=false" :periodId="periodId"></statistics-progress>
  </el-row>
</template>

<script>
import addStatistics from '@/components/statistics/add-statistics'
import statisticsProgress from '@/components/statistics/statistics-progress'
import { getListPeriod } from '@/api/statistics'

export default {
  components: {
    addStatistics,
    statisticsProgress
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      statisticsDialog: false,
      statisticsData: null,
      periodId: '',
      progressDialog: false
    }
  },
  created () {
    this.getListPeriod()
  },
  methods: {
    async getListPeriod () {
      const res = await getListPeriod({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      })
      // console.log('res', res)
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    shiftArr (arr) {
      return JSON.parse(arr)
    },

    // 添加周期
    addPeriod () {
      this.statisticsDialog = true
    },

    // 重新统计
    againInfo (row) {
      this.periodId = row.id
      this.progressDialog = true
    },

    // 修改并统计
    updateInfo (row) {
      this.statisticsData = row
      this.statisticsDialog = true
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListPeriod()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListPeriod()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
